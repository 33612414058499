<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Form</Heading>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Inputs</Heading>
							<Paragraph>An input is a form field that a user can type a line of text into.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stage>
							<Form>
								<FormContainer size="12">
									<Theme ar dl ed hv sd ds>
										<Input label="Name" />
									</Theme>
									<Theme wm>
										<Input placeholder="Name" />
									</Theme>
								</FormContainer>
								<FormContainer size="12">
									<Theme ar dl ed hv sd ds>
										<Input label="Email" />
									</Theme>
									<Theme wm>
										<Input placeholder="Email" />
									</Theme>
								</FormContainer>
								<FormContainer size="12">
									<Theme ar dl ed hv sd ds>
										<Input label="Subject" />
									</Theme>
									<Theme wm>
										<Input placeholder="Subject" />
									</Theme>
								</FormContainer>
							</Form>
						</Stage>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Textarea</Heading>
							<Paragraph>An textarea is a form field that a user can type multiple lines of text into.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stage>
							<Form>
								<FormContainer size="12">
									<Theme ar dl ed hv sd ds>
										<Textarea label="Message" />
									</Theme>
									<Theme wm>
										<Textarea placeholder="Message" />
									</Theme>
								</FormContainer>
							</Form>
						</Stage>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Form Layout</Heading>
							<Paragraph>With the form and form container components, the form fields have a wide range of size options. Apply the size="" prop to the form container.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stage>
							<Form>
								<FormContainer size="12">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 12" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 12" />
									</Theme>
								</FormContainer>
								<FormContainer size="1">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 1" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 1" />
									</Theme>
								</FormContainer>
								<FormContainer size="11">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 11" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 11" />
									</Theme>
								</FormContainer>
								<FormContainer size="2">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 2" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 2" />
									</Theme>
								</FormContainer>
								<FormContainer size="10">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 10" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 10" />
									</Theme>
								</FormContainer>
								<FormContainer size="3">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 3" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 3" />
									</Theme>
								</FormContainer>
								<FormContainer size="9">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 9" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 9" />
									</Theme>
								</FormContainer>
								<FormContainer size="4">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 4" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 4" />
									</Theme>
								</FormContainer>
								<FormContainer size="8">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 8" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 8" />
									</Theme>
								</FormContainer>
								<FormContainer size="5">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 5" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 5" />
									</Theme>
								</FormContainer>
								<FormContainer size="7">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 7" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 7" />
									</Theme>
								</FormContainer>
								<FormContainer size="6">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 6" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 6" />
									</Theme>
								</FormContainer>
								<FormContainer size="6">
									<Theme ar dl ed hv sd ds>
										<Input label="Size 6" />
									</Theme>
									<Theme wm>
										<Input placeholder="Size 6" />
									</Theme>
								</FormContainer>
							</Form>
						</Stage>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Heading size="5">Form Container Properties</Heading>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">size</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">1-12</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the size of the form container.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">align</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										left<br/>
										middle<br/>
										right<br/>
										top<br/>
										bottom<br/>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Alignment of the form fields in the form container.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Heading size="5">Input Properties</Heading>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">
										<Theme ar dl ed hv sd ds>
											label
										</Theme>
										<Theme wm>
											placeholder
										</Theme>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">
										<Theme dl ed hv ds>
											Defines the descriptive text above the input.
										</Theme>
										<Theme ar wm sd>
											Defines the descriptive text inside the input.
										</Theme>
									</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">id</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Must be a unique string assigned to the input.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Heading size="5">Textarea Properties</Heading>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">
										<Theme ar dl ed hv sd ds>
											label
										</Theme>
										<Theme wm>
											placeholder
										</Theme>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">
										<Theme dl ed hv ds>
											Defines the descriptive text above the textarea.
										</Theme>
										<Theme ar wm sd>
											Defines the descriptive text inside the textarea.
										</Theme>
									</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">id</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Must be a unique string assigned to the textarea.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      theme: ''
		}
  },
  mounted() {
		this.stageTheme(this.theme);
  },
}
</script>

<style lang="scss" scoped>
</style>
